<template>
  <b-card no-body>
    <b-card-header style="width: 100%">
      <b-card-title>Phổ điểm thi của các lớp độc lập trong từng môn học</b-card-title>
      <b-dropdown
        dropright
        variant="link"
        toggle-class="p-0"
        no-caret
      >
        <template #button-content>
          <feather-icon
            class="text-body"
            icon="FilterIcon"
          />
        </template>
        <b-dropdown-item
          v-for="(item, index) in itemSubject"
          @click="selectItem(item)"
          :key="index">
          {{ item.subjectName }}
        </b-dropdown-item>
      </b-dropdown>
    </b-card-header>

    <b-card-body>
      <vue-apex-charts
        ref="chart"
        type="scatter"
        height="400"
        :options="scatterChart.chartOptions"
        :series="scatterChart.series"
      />
    </b-card-body>
    <b-card class="text-center" > {{ this.subTitle }} </b-card>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BButton, BButtonGroup, BDropdown, BDropdownItem, BCardTitle,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import Ripple from 'vue-ripple-directive'
import { mapActions, mapGetters } from "vuex";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BCardTitle, BDropdownItem, BDropdown,
    BCard,
    VueApexCharts,
    BCardHeader,
    BCardBody,
    BButton,
    BButtonGroup,
  },
  directives: {
    Ripple,
  },
  props: {
    filter: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      itemSubject: [],
      subTitle: null,
      checkLoadSubTitle: false,
      randomColor: null,
      scatterChart: {
        series: [],
        chartOptions: {
          chart: {
            zoom: {
              enabled: true,
              type: 'xy',
            },
            toolbar: {
              show: false,
            },
          },
          grid: {
            xaxis: {
              lines: {
                show: true
              }
            },
            yaxis: {
              lines: {
                show: true
              }
            },
          },
          colors: [
            '#836AF9',
            '#ffe800',
            '#28dac6',
            '#6e6b7b',
            '#FDAC34',
            '#299AFF',
            '#4F5D70',
            '#2c9aff',
            '#84D0FF',
            '#ff4961',
            '#666ee8'
          ],
          legend: {
            show: true,
            position: 'top',
            horizontalAlign: 'left',
          },
          xaxis: {
            tickAmount: 10,
            labels: {
              formatter(val) {
                return parseFloat(val).toFixed(1)
              },
            },
          },
          yaxis: {
            // tickAmount: 7
          },
        },
      },
    }
  },
  watch: {
    'filter.trainingSystemId': async function () {
      await this.getDataFromStore()
    },
    'filter.yearId': async function () {
      await this.getDataFromStore()
    },
    'filter.semesterId': async function () {
      await this.getDataFromStore()
    }
  },
  computed: {
    ...mapGetters( {
      subjectsByYearAndSemester: 'chart/subjectsByYearAndSemester',
      countScoreByCreditClass: 'chart/countScoreByCreditClass',
    }),
  },
  async created() {
    await this.getDataFromStore()
  },
  methods: {
    ...mapActions( {
      getSubjectsByYearAndSemester: 'chart/getSubjectsByYearAndSemester',
      getCountScoreByCreditClass: 'chart/getCountScoreByCreditClass',
    } ),
    async getDataFromStore() {
      this.isLoading = true;
      this.itemSubject = []
      try {
        await Promise.all([
          this.getCountScoreByCreditClass(this.filter),
          this.getSubjectsByYearAndSemester(this.filter)
        ])
        if (this.subjectsByYearAndSemester.length > 0) {
          this.subjectsByYearAndSemester.forEach((item, key) => {
            this.itemSubject.push(this.subjectsByYearAndSemester[key])
          })
        }
        if (this.countScoreByCreditClass.length > 0) {
          this.scatterChart.series = []
          let previousCreditClassCode = null;
          let itemData = {
            name: null,
            data: []
          }
          this.scatterChart.chartOptions.colors = []
          this.countScoreByCreditClass.forEach((item, key) => {
            const currentCreditClassCode = item.creditClassCode;
            if (key === 0 || currentCreditClassCode !== previousCreditClassCode) {
              itemData.name = item.creditClassCode
            }
            itemData.data.push([item.score, item.countScore])
            if (key === this.countScoreByCreditClass.length - 1 || this.countScoreByCreditClass[key + 1].creditClassCode !== currentCreditClassCode) {
              this.scatterChart.series.push(itemData)
              itemData = {
                name: null,
                data: []
              }
            }
            previousCreditClassCode = currentCreditClassCode;
          })
        }
        if (this.countScoreByCreditClass.length === 0 ) {
          this.checkLoadSubTitle = false;
        }
        this.checkLoadSubTitle = true;
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        });
      } finally {
        this.isLoading = false;
      }
    },
    async selectItem(item) {
      this.filter.subjectId = item?.subjectId
      await this.getDataFromStore()
      if (item != null) {
        this.checkLoadSubTitle === true ? this.subTitle = item.subjectName : ''
        if (this.checkLoadSubTitle === false) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Môn học '+ item.subjectName + ' chưa có điểm!',
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        }
      }
    },
  }
}
</script>
